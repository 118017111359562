import { Button } from "antd";
import parse from "html-react-parser";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import gift from "../../../assets/images/landing-gift.png";
import AuthFooter from "../../../components/Auth/AuthFooter/AuthFooter";
import AuthHeaderBottom from "../../../components/Auth/AuthHeaderBottom/AuthHeaderBottom";
import FbCard from "../../../components/Auth/FbCard/FbCard";
import PageTitle from "../../../components/Auth/PageTitle/PageTitle";
import Privileges from "../../../components/Auth/Privileges/Privileges";
import ButtonPrimary from "../../../components/Buttons/ButtonPrimary/ButtonPrimary";
import { Constants } from "../../../core/helpers/constants";
import { router } from "../../../core/helpers/router";
import { AuthType } from "../../../core/models/custom/enums/authType";
import FirebaseService from "../../../core/services/firebase.service";
import "./LandingPage.scss";

interface IState {
  isLandingModalVisible: boolean;
}

class LandingPage extends React.Component {
  state: IState = {
    isLandingModalVisible: false,
  };

  componentDidMount() {
    FirebaseService.fireEvent(FirebaseService.event_user_count_landing_view);
  }

  // private handleLandingModalClose(isApproved: boolean): void {
  //   if (isApproved) {
  //     history.push(`${router.LOGIN_MSISDN}?authType=${AuthType.signup}`);
  //   } else {
  //     this.setState({ isLandingModalVisible: false });
  //   }
  // }

  // private handleWheelClick(): void {
  //   FirebaseService.fireEvent(FirebaseService.event_landing_wheel_click);
  //   this.setState({ isLandingModalVisible: true });
  // }

  private renderBanner(): ReactNode {
    return (
      <div className="banner">
        <img className="banner-gift" src={gift} alt="gift" />
        <div className="banner-content">
          {parse(FirebaseService.getValue(FirebaseService.landing_banner_text))}
          <Button
            className="banner-btn"
            type="primary"
            onClick={() => {
              FirebaseService.fireEvent(
                FirebaseService.event_user_count_landing_banner_click
              );
              window.open(Constants.wheelUrl, "_blank");
            }}
          >
            {parse(
              FirebaseService.getValue(FirebaseService.landing_banner_button)
            )}
          </Button>
        </div>
      </div>
    );
  }

  private renderCard(): ReactNode {
    return <FbCard isBG={true} />;
  }

  private renderHeader(): ReactNode {
    return (
      <div className="header">
        <PageTitle title="TARAFTAR KARTLI OL" />
        <p className="subtitle">Ayrıcalıklı ol!</p>
      </div>
    );
  }

  // private renderWheel(): ReactNode {
  //   return (
  //     <div className="wheel-wrapper" onClick={() => this.handleWheelClick()}>
  //       <div className="wheel-content">
  //         <img className="spinning-wheel" src={wheel} alt="wheel" />
  //         <img className="button" src={wheelButton} alt="button" />
  //         <img className="pointer" src={wheelPointer} alt="pointer" />
  //       </div>
  //     </div>
  //   );
  // }

  private renderText(): ReactNode {
    return (
      <div className="text">
        {/* {this.renderWheel()} */}
        Fenerbahçeli’nin Taraftar Kart’ı
        <br />
        var! Şimdi abone ol, avantajları kaçırma!
      </div>
    );
  }

  private renderButton(): ReactNode {
    return (
      <NavLink
        className="link"
        to={{
          pathname: router.LOGIN_MSISDN,
          search: `authType=${AuthType.login}`,
        }}
      >
        <ButtonPrimary
          class="button-yellow"
          text="GİRİŞ YAP"
          arrowSuffix={true}
        />
      </NavLink>
    );
  }

  render() {
    return (
      <div id="landing-page">
        {this.renderBanner()}
        <div className="container">
          <div className="page-content">
            <AuthHeaderBottom />
            <div className="d-flex flex-column d-lg-none my-auto pb-4">
              {this.renderCard()}
              {this.renderHeader()}
              {this.renderText()}
              {this.renderButton()}
            </div>
            <div className="d-none d-lg-flex lg-wrapper my-auto py-4">
              <div className="side left">
                {this.renderHeader()}
                {this.renderText()}
                {this.renderButton()}
              </div>
              <div className="side right">{this.renderCard()}</div>
            </div>
          </div>
        </div>
        <Privileges />
        <AuthFooter />

        {/* <WheelLandingModal
          isVisible={this.state.isLandingModalVisible}
          callbackClose={(isApproved) =>
            this.handleLandingModalClose(isApproved)
          }
        /> */}
      </div>
    );
  }
}

export default LandingPage;
