import React from "react";
import cardGrayWithBg from "../../../assets/images/fb-taraftart-card-gray-with-bg.png";
import cardGray from "../../../assets/images/fb-taraftart-card-gray.png";
import cardWithBg from "../../../assets/images/fb-taraftart-card-with-bg.png";
import card from "../../../assets/images/fb-taraftart-card.png";
import "./FbCard.scss";

interface IProps {
  isBG?: boolean;
  isGray?: boolean;
}

const FbCard = (props: IProps) => {

  return (
    <div id="fb-card">
      {
        props.isBG
          ? props.isGray
            ? <img src={cardGrayWithBg} alt="card"/>
            : <img src={cardWithBg} alt="card"/>
          : props.isGray
            ? <img src={cardGray} alt="card"/>
            : <img src={card} alt="card"/>
      }
    </div>
  );
}

export default FbCard;
