import parse from "html-react-parser";
import React, { ReactNode } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import buttonImage from "../../../assets/images/next.svg";
import paymentMethodCardActive from "../../../assets/images/payment-method-card-active.svg";
import paymentMethodCard from "../../../assets/images/payment-method-card.svg";
import paymentMethodMobileActive from "../../../assets/images/payment-method-mobile-active.svg";
import paymentMethodMobile from "../../../assets/images/payment-method-mobile.svg";
import privilegeDiscount from "../../../assets/images/privilege-discount-yellow.svg";
import privilegeGift from "../../../assets/images/privilege-gift-yellow.svg";
import privilegeTicket from "../../../assets/images/privilege-ticket-yellow.svg";
import privilegeWheel from "../../../assets/images/privilege-wheel-yellow.svg";
import optionActive from "../../../assets/images/selection-card-active.png";
import optionPassive from "../../../assets/images/selection-card-passive.png";
import AuthFooter from "../../../components/Auth/AuthFooter/AuthFooter";
import AuthHeaderBottom from "../../../components/Auth/AuthHeaderBottom/AuthHeaderBottom";
import AuthHeaderMobile from "../../../components/Auth/AuthHeaderMobile/AuthHeaderMobile";
import FbCard from "../../../components/Auth/FbCard/FbCard";
import PageTitle from "../../../components/Auth/PageTitle/PageTitle";
import ButtonPrimary from "../../../components/Buttons/ButtonPrimary/ButtonPrimary";
import { setProfileWarningVisibility } from "../../../core/actions/app";
import { Constants } from "../../../core/helpers/constants";
import { history } from "../../../core/helpers/history";
import { router } from "../../../core/helpers/router";
import {
  getStorage,
  setStorage,
  storageRecaptcha,
} from "../../../core/helpers/storage";
import { Utilities } from "../../../core/helpers/utilities";
import { IStore } from "../../../core/reducers";
import "./SubscriptionPage.scss";

interface IProps {
  match: any;
  msisdn?: string;
  setProfileWarningVisibility: (isVisible: boolean) => void;
}

interface IState {
  step: Step;
  subscriptionPeriod: SubscriptionPeriod;
  paymentMethod: PaymentMethod;
  recaptchaToken: string;
  isCaptchaError: boolean;
}

enum Step {
  period = "period",
  method = "method",
}

export enum SubscriptionPeriod {
  monthly = "monthly",
  yearly = "yearly",
}

enum PaymentMethod {
  mobile = "mobile",
  vpos = "vpos",
}

class SubscriptionPage extends React.Component<IProps> {
  state: IState = {
    step: Step.period,
    subscriptionPeriod: SubscriptionPeriod.monthly,
    paymentMethod: PaymentMethod.vpos,
    recaptchaToken: "",
    isCaptchaError: false,
  };

  private readonly isMsisdnTurkish: boolean = false;

  constructor(props: IProps) {
    super(props);
    const msisdn = this.props.msisdn;
    if (!msisdn) {
      history.push(router.LOGIN_MSISDN);
    } else {
      this.isMsisdnTurkish = Utilities.isMsisdnTurkish(msisdn);
    }
  }

  componentDidMount() {
    // Handles pop back from next page (removes subscriptionPeriod from url)
    if (this.props.match.params.subscriptionPeriod) {
      history.push(router.SUBSCRIPTION);
    }
    if (!this.isMsisdnTurkish) {
      this.setState({ subscriptionPeriod: SubscriptionPeriod.yearly });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (prevProps.match !== this.props.match) {
      if (this.props.match.params.subscriptionPeriod) {
        this.setState({ step: Step.method });
      } else {
        this.setState({ step: Step.period });
      }
    }
  }

  private setSubscriptionPeriod(subscriptionPeriod: SubscriptionPeriod): void {
    this.setState({ subscriptionPeriod });
  }

  private setPaymentMethod(paymentMethod: PaymentMethod): void {
    this.setState({ paymentMethod });
  }

  private handleContinue(): void {
    if (Utilities.isNoPaymentTime()) {
      this.props.setProfileWarningVisibility(true);
      return;
    }
    const step = this.state.step;
    if (step === Step.period) {
      if (this.isMsisdnTurkish) {
        if (this.state.subscriptionPeriod === SubscriptionPeriod.yearly) {
          this.redirectToPayByMe(this.state.subscriptionPeriod);
        } else {
          /**
           * PLAY-2541
           * Aylık abonelik seçildiğinde kredi kartı-mobil ödeme seçeneği olan sayfa kaldırırlıp
           * direkt vpos ödemesine yönlendirme yapılması gerekiyor
           */
          // todo revert back (delete navigateToPayByMe line, use commented out logic) (Mobile Payment)
          history.push(
            `${router.SUBSCRIPTION}/${this.state.subscriptionPeriod}`
          ); // todo delete this line
          // Utilities.navigateToPayByMe(this.state.subscriptionPeriod); // todo use this line
        }
      } else {
        this.redirectToPayByMe(SubscriptionPeriod.yearly);
      }
    } else if (step === Step.method) {
      if (this.state.paymentMethod === PaymentMethod.mobile) {
        history.push(router.MOBILE_PAYMENT);
      } else {
        this.redirectToPayByMe(this.state.subscriptionPeriod);
      }
    }
  }

  private redirectToPayByMe(period: SubscriptionPeriod) {
    const isRecaptcha = getStorage(storageRecaptcha);
    if (isRecaptcha) {
      if (!this.state.recaptchaToken) {
        this.setState({
          isCaptchaError: true,
        });
        return;
      }
      setStorage(storageRecaptcha, this.state.recaptchaToken);
    }
    Utilities.navigateToPayByMe(period);
  }

  private handleRecaptchaChange(token: string | null): void {
    if (token) {
      this.setState({
        isCaptchaError: false,
        recaptchaToken: token,
      });
    }
  }

  private renderPrivileges(): ReactNode {
    return (
      <div className="privileges row mt-4 ml-0 ml-lg-3">
        <div className="col-12 col-md-5 p-0">
          <div className="privilege-wrapper">
            <img className="icon" src={privilegeTicket} alt="ticket" />
            <div className="privilege-info">
              <span className="title">Öncelikli maç bileti</span>
              <span className="text">Maç biletini herkesten önce al!</span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-7 p-0">
          <div className="privilege-wrapper">
            <img className="icon" src={privilegeGift} alt="gift" />
            <div className="privilege-info">
              <span className="title">Hediye 4000 MetaByte</span>
              <span className="text">Her ay hediye 4000 MetaByte kazan!</span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-5 p-0">
          <div className="privilege-wrapper">
            <img className="icon" src={privilegeDiscount} alt="discount" />
            <div className="privilege-info">
              <span className="title">Fenerium’da %10 indirim</span>
              <span className="text">
                Fenerium ürünlerini %10 indirimli al!
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-7 p-0">
          <div className="privilege-wrapper">
            <img className="icon" src={privilegeWheel} alt="wheel" />
            <div className="privilege-info">
              <span className="title">Çark-ı Fener hakkı</span>
              <span className="text">
                Loca keyfi, imzalı forma ve birçok sürpriz hediye kazan!
              </span>
            </div>
          </div>
        </div>
        <p className="end-note d-block d-md-none">VE ÇOK DAHA FAZLASI...</p>
        <p className="end-note d-none d-md-block">Ve çok daha fazlası</p>
      </div>
    );
  }

  private renderOption(period: SubscriptionPeriod): ReactNode {
    const isSelected = this.state.subscriptionPeriod === period;
    const isYearly = period === SubscriptionPeriod.yearly;
    return (
      <div
        className={`option ${isYearly && "yearly"} ${isSelected && "selected"}`}
        onClick={() => this.setSubscriptionPeriod(period)}
      >
        <img
          className="selection-icon"
          src={isSelected ? optionActive : optionPassive}
          alt="tick"
        />
        {isYearly && <div className="discount">İNDİRİMLİ</div>}
        <div className="price-original">&nbsp;₺109&nbsp;</div>
        <div className="price">
          <span className="main">
            ₺
            {isYearly ? (
              <span>
                91<span className="float">,58</span>
              </span>
            ) : (
              109
            )}
          </span>
          &nbsp;/ay
        </div>
        <div className="note">*yıllık ödeme alınır.</div>
      </div>
    );
  }

  private renderOptionForeign(): ReactNode {
    return (
      <div className="option selected foreign">
        <img className="selection-icon" src={optionActive} alt="tick" />
        <div className="price">
          <span className="main">₺1.299</span>
          &nbsp;/yıl
        </div>
      </div>
    );
  }

  private renderOptionPaymentMethod(method: PaymentMethod): ReactNode {
    const isSelected = this.state.paymentMethod === method;
    let icon = "";
    let content = "";
    switch (method) {
      case PaymentMethod.mobile:
        icon = isSelected ? paymentMethodMobileActive : paymentMethodMobile;
        content = "<span>Mobile<br/>Ödeme</span>";
        break;
      case PaymentMethod.vpos:
        icon = isSelected ? paymentMethodCardActive : paymentMethodCard;
        content = "<span>Kredi<br/>Kartı</span>";
        break;
    }
    return (
      <div
        className={`option ${isSelected && "selected"}`}
        onClick={() => this.setPaymentMethod(method)}
      >
        <img
          className="selection-icon"
          src={isSelected ? optionActive : optionPassive}
          alt="tick"
        />
        <img className="method-icon" src={icon} alt="tick" />
        <div className="method">{parse(content)}</div>
      </div>
    );
  }

  private renderOptions(): ReactNode {
    return (
      <div className="option-wrapper">
        <label className="option-title">Abonelik teklifini seç</label>
        {this.isMsisdnTurkish ? (
          <div className="d-flex justify-content-between">
            {this.renderOption(SubscriptionPeriod.monthly)}
            {this.renderOption(SubscriptionPeriod.yearly)}
          </div>
        ) : (
          <div className="d-flex justify-content-between">
            {this.renderOptionForeign()}
          </div>
        )}
        {this.state.subscriptionPeriod === SubscriptionPeriod.yearly &&
          this.renderRecaptcha()}
        {this.renderButton()}
      </div>
    );
  }

  private renderOptionsPaymentMethod(): ReactNode {
    return (
      <div className="option-wrapper">
        <label className="option-title">Ödeme yöntemini seç</label>
        <div className="d-flex justify-content-between">
          {this.renderOptionPaymentMethod(PaymentMethod.vpos)}
          {this.renderOptionPaymentMethod(PaymentMethod.mobile)}
        </div>
        {this.state.paymentMethod === PaymentMethod.vpos &&
          this.renderRecaptcha()}
        {this.renderButton()}
      </div>
    );
  }

  private renderRecaptcha(): ReactNode {
    const isRecaptcha = getStorage(storageRecaptcha);
    if (isRecaptcha) {
      return (
        <ReCAPTCHA
          className={`recaptcha mt-3 ${
            this.state.isCaptchaError ? "error" : ""
          }`}
          sitekey={Constants.recaptchaSiteKey}
          onChange={(token) => this.handleRecaptchaChange(token)}
        />
      );
    }
  }

  private renderButton(): ReactNode {
    return (
      <ButtonPrimary
        class="button-yellow mb-3"
        text={
          <React.Fragment>
            <span className="button-text">DEVAM ET</span>
            <img className="button-icon" src={buttonImage} alt="continue" />
          </React.Fragment>
        }
        callback={() => this.handleContinue()}
      />
    );
  }

  render() {
    return (
      <div id="subscription-page">
        <div className="container">
          <div className="page-content">
            <AuthHeaderBottom />
            <AuthHeaderMobile pageTitle="ABONE OL" />
            <div className="d-flex flex-column d-lg-none my-auto">
              <div className="d-flex justify-content-center align-items-center">
                <div className="mobile-info">
                  <p className="title">Taraftar Kartlı ol!</p>
                  <p className="text">AYRICALIKLI OL!</p>
                </div>
                <FbCard />
              </div>
              {this.renderPrivileges()}
              {this.state.step === Step.method
                ? this.renderOptionsPaymentMethod()
                : this.renderOptions()}
            </div>

            <div className="d-none d-lg-flex m-auto">
              <div className="col-left">
                <div className="desktop-box">
                  <PageTitle title="TARAFTAR KARTLI OL" />
                  <p className="subtitle">Ayrıcalıklı ol!</p>
                  <span className="note">
                    Fenerbahçeli'nin Taraftar Kart'ı var!
                    <br />
                    Şimdi abone ol, avantajları kaçırma!
                  </span>
                  {this.state.step === Step.method
                    ? this.renderOptionsPaymentMethod()
                    : this.renderOptions()}
                </div>
              </div>
              <div className="col-right">
                <FbCard isBG={true} />
                {this.renderPrivileges()}
              </div>
            </div>

            <AuthFooter />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setProfileWarningVisibility,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    msisdn: store.loginMsisdn.msisdn,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
